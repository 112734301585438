import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import RoomListing from "../components/RoomListing";
import { ROOMS_ROW } from "../queries";
import SearchField from "./SearchField";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuerySearch() {
  return new URLSearchParams(useLocation().search);
}

const PageSearch = () => {
  const [searchKey, setKey] = useState("");
  const { t } = useTranslation()
  return (
    <div>
      <SearchField searchKey={searchKey} setKey={setKey} />
      {searchKey.length > 3 ? (
        <RoomListing searchKey={searchKey} />
      ) : (
          <div className="container" style={{ minHeight: 300 }}>
            <span>{t("Start typing to search")}</span>
          </div>
        )}
    </div>
  );
};

export default PageSearch;
