import Shape from "./Shape";

const SVG = ({
  shapes,
  width,
  height,
  selected,
  onMouseOver = null,
  onBlur = { onBlur },
}) => (
  <svg viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
    {shapes.map((sh) => (
      <Shape
        item={sh}
        active={sh.targetable_id == selected}
        onMouseOver={onMouseOver}
        onBlur={onBlur}
      />
    ))}
  </svg>
);

export default SVG;
