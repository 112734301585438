import { connectViewport } from "react-viewport-utils"
import PostCard from "../components/PostCard"
import AdBanners from "./AdBanners"


const PostGrid = ({ posts = [], dimensions: { width } }) => {

    const type = width >= 996 ? "side" : "side_mobile"

    const first = posts.slice(0, 4)
    const rest = posts.slice(4)

    return (
        <>
            <div className="row">
                <div className="col-md-8">
                    <div className="row">
                        {first.map((p, index) => (
                            <PostCard post={p} className="col-md-6" key={index} />
                        ))}
                    </div>
                </div>
                <div className="col-md-4">
                    <AdBanners type={type} />
                </div>
            </div>
            <div className="row">
                {rest.map((p, index) => (
                    <PostCard post={p} className="col-md-4" key={index} />
                ))}
            </div>
        </>

    )
}
export default connectViewport()(PostGrid);