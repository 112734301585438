import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { GET_FLOORS } from "../../queries";
import Modal from "../../utils/Modal";
import Main from "./Main";
import "./_plan.scss";

const Plan = () => {
  const { data, error, loading } = useQuery(GET_FLOORS);
  const history = useHistory();
  const { type } = useParams();

  if (error) {
    return "error";
  }

  return (
    <Modal
      open={true}
      onClose={() => {
        history.push("/");
        document.body.classList.remove(`dimmedplan`);
      }}
      className="modal-container--lg"
      id="plan"
    >
      <Main floors={data ? data.floors : []} type={type} />
    </Modal>
  );
};

export default Plan;
