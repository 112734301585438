import { ApolloClient, InMemoryCache } from "@apollo/client";

let client = null;

const create = () => {
  if (!client) {
    client = new ApolloClient({
      uri: process.env.REACT_APP_GRAPHQL_SERVER,
      cache: new InMemoryCache(),
    });
  }
  return client;
};

export default create();
