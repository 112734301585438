import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import Selection from "../../selection";
import Picture from "../Picture";
import FloorNav from "./FloorNav";

const types = ["mall", "kids", "icering", "administration"];

const Main = ({ floors, type }) => {
  const [current, setCurrent] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    setCurrent(floors.filter((f) => f.type == type));
  }, [floors, type, floors.length]);

  return (
    <div>
      <ul className="block__nav">
        {types.map((type) => (
          <li key={type}>
            <NavLink to={`/block/${type}`}>{t(type)}</NavLink>
          </li>
        ))}
      </ul>
      <FloorMap items={current} />
    </div>
  );
};

const FloorMap = ({ items }) => {
  const [selected, select] = useState(null);
  const [room, setRoom] = useState(null);

  useEffect(() => {
    if (items.length) {
      select(items[0]);
    } else {
      select(null);
    }
  }, [items, items.length]);

  const handleSelect = (item) => {
    select(item);
  };

  const handleMouseOver = (targetable_id) => {
    console.log(targetable_id);
    setRoom(targetable_id);
  };

  const handleBlur = () => {
    setRoom(null);
  };

  const roomInfo = room
    ? selected.rooms.filter((r) => r.id === room * 1)[0]
    : null;
  console.log(roomInfo);
  return selected ? (
    <div className="plan__floor">
      {items.length > 1 && (
        <FloorNav
          items={items}
          onSelect={handleSelect}
          current={selected ? selected.id : null}
        />
      )}
      <main className={items.length > 1 ? "plan" : "plan-single"}>
        <div className="floor-map">
          {!!roomInfo && (
            <div className="room_info">
              <Link to={`/rooms/${roomInfo.id}`}>{roomInfo.name}</Link>
            </div>
          )}
          <Selection
            image={selected.map}
            shapes={selected.map.shapes.map((s) => ({
              ...s,
              url: s.targetable_id ? `/rooms/${s.targetable_id}` : null,
            }))}
            onMouseOver={handleMouseOver}
            selected={room}
            onBlur={handleBlur}
          />
          <div className="row">
            {selected.rooms
              .filter((r) => !!r.logo)
              .map((r) => (
                <div className="col-md-3 col-lg-2 col-4" key={r.id}>
                  <Link
                    to={`/rooms/${r.id}`}
                    title={r.name}
                    className="plan__room"
                  >
                    <Picture image={r.logo} alt={r.name} />
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </main>
    </div>
  ) : (
    <div className="floor-map"></div>
  );
};

export default Main;
