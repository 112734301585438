
import { useKeenSlider } from "keen-slider/react";
import { Helmet } from "react-helmet";
import _ from "lodash"
import { useTranslation } from "react-i18next";
import { connectViewport } from "react-viewport-utils";
import { Facebook, Instagram, Telegram } from "../assets/icons";
import { useSetting } from "../utils/SettingsProvider";
import Carousel from "./Carousel";
import Picture from "./Picture";
import "./room-page.scss";

const slidesCountByWidth = (width) => {
  if (width > 996)
    return 3;
  if (width > 768)
    return 2;

  return 1
}

const RoomPage = ({ room, dimensions: { width } }) => {
  const [sliderRef] = useKeenSlider({ slidesPerView: 1 });
  const s = useSetting()
  const { t } = useTranslation()
  const perview = slidesCountByWidth(width)
  return (
    <article className="room">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {s(`trans_${room.type.toLowerCase()}`)} {room.name}:
        </title>
        <meta description={room.excerpt} />
      </Helmet>
      <div className="room__banner">
        <div ref={sliderRef} className="keen-slider">
          {room.banners.map((image) => (
            <div className="keen-slider__slide" key={image.id}>
              <Picture
                image={image}
                alt={room.name}
                className="room__banner-img"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="container room__info">
        <h1>{room.name}</h1>
        <div className="row">
          <div className="col-md-2 col-4">
            {!!room.logo && (
              <Picture
                image={room.logo}
                alt={room.name}
                className="room__logo"
              />
            )}
          </div>
          <div className="col-md-4 col-8 room__meta">
            <h3>{t("Contacts")}</h3>
            <ul className="room__phone">
              {room.phone_list.map((v, i) => (
                <li key={i}>
                  <a href={`tel:${v}`} title={v}>
                    {v}
                  </a>
                </li>
              ))}
            </ul>
            <h3>{t("Address")}</h3>
            <address>
              {t("Floor number")}: {room.floor.number}. {!!room.number && <>{t("Room number")} {room.number}</>}
            </address>
          </div>
          <div className="col-md-6 offset-lg-0 offset-4 col-8 room__meta">
            <h3>{t("Socials")}</h3>
            <ul className="room__socials">
              {!!room.telegram && (
                <li>
                  <a
                    href={room.telegram}
                    title="Telegram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Telegram />
                    <span className="sr-only">{room.telegram}</span>
                  </a>
                </li>
              )}
              {!!room.instagram && (
                <li>
                  <a
                    href={room.instagram}
                    title="Instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Instagram />
                    <span className="sr-only">{room.instagram}</span>
                  </a>
                </li>
              )}
              {!!room.facebook && (
                <li>
                  <a
                    href={room.facebook}
                    title="Facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Facebook />
                    <span className="sr-only">{room.facebook}</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div
            className="col-12 room__description"
            dangerouslySetInnerHTML={{ __html: room.description }}
          />
        </div>
      </div>
      {room.gallery.length > 0 && (
        <div className="container">
          <Carousel perview={perview}>
            {room.gallery.map((item, i) => (
              <Picture image={item} key={i} className="room__inner-gallery" />
            ))}
            {_.range(0, _.clamp(perview - room.gallery.length, 0, perview)).map((k, i) => <div key={i} />)}
          </Carousel>
        </div>
      )}
    </article>
  );
};

export default connectViewport()(RoomPage);
