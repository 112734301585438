import { useEffect, useRef } from "react";
import "./modal.scss";

const Modal = ({ children, className = "", onClose, open, id = "" }) => {
  const dimmerRef = useRef(null);
  const handleClose = (e) => {
    e.preventDefault();
    if (e.target === dimmerRef.current) {
      onClose();
    }
  };
  useEffect(() => {
    if (open) {
      document.body.classList.add(`dimmed${id}`);
    } else {
      document.body.classList.remove(`dimmed${id}`);
    }
  }, [open]);
  return (
    open && (
      <div className="modal" onClick={handleClose} ref={dimmerRef}>
        <div className={`modal-container ${className}`}>
          <button
            type="button"
            className="close modal-close"
            aria-label="Close"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {children}
        </div>
      </div>
    )
  );
};

export default Modal;
