import React from "react";
import Banner from "./Banner";
import { useQuery } from "@apollo/client";
import { GET_BANNERS } from "../queries";
import { useKeenSlider } from "keen-slider/react";
import { Loading } from "../utils/LoaderProvider";

const Banners = () => {
  const { loading, error, data } = useQuery(GET_BANNERS, {
    variables: { type: "main" }
  });
  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  const { banners } = data;
  return (
    <header>
      {!!banners.length && (
        <BannerSlider banners={banners} />
      )}
    </header>
  );
};

export const BannerSlider = ({ banners }) => {
  const [sliderRef] = useKeenSlider({ slidesPerView: 1 });
  return <div ref={sliderRef} className="keen-slider">
    {banners.map((b, index) => (
      <Banner item={b} key={index} />
    ))}
  </div>
}

export default Banners;
