import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./scss/_bootstrap.scss";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import gqlClient from "./GraphQLClient";
import "./localization";

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={gqlClient}>
      <Suspense fallback={() => <></>}>
        <App />
      </Suspense>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
