import { useQuery } from "@apollo/client";
import { range } from "lodash/fp";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ROOMS_ROW } from "../queries";
import RoomCardShimmer from "../shimmers/RoomCardShimmer";
import RoomCard from "./RoomCard";

const PER_PAGE = 8;

const RoomListing = ({ searchKey }) => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation()
  const { loading, data } = useQuery(ROOMS_ROW, {
    variables: { search: searchKey, page, limit: PER_PAGE },
  });
  if (loading) {
    return (
      <main>
        <div className="container">
          <div className="row">
            {range(0)(3).map((i) => (
              <div className="col-md-4 col-6" key={i}>
                <RoomCardShimmer />
              </div>
            ))}
          </div>
        </div>
      </main>
    );
  }
  const rooms = data ? data.rooms.data : [];
  const pages = range(1)(Math.ceil(data.rooms.total / PER_PAGE) + 1);

  const handlePageChange = (page) => (e) => {
    e.preventDefault();
    setPage(page);
  };
  return (
    <main style={{ minHeight: 400 }}>
      <div className="container">
        {!rooms.length && (
          <div className="alert alert-secondary">
            <div className="alert-body">
              <h5>{t("No result found")}</h5>
              <p>{t("Specifiy your search key")}</p>
            </div>
          </div>
        )}
        <div className="row">
          {rooms.map((room) => (
            <div className="col-md-4 col-6" key={room.id}>
              <RoomCard item={room} />
            </div>
          ))}
        </div>
        {pages.length > 1 && (
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item">
                <button
                  class="page-link"
                  type="button"
                  onClick={handlePageChange(page - 1)}
                  disabled={page === 1}
                >
                  Previous
                </button>
              </li>
              {pages.map((p) => (
                <li class={`page-item${p === page ? " active" : ""}`} key={p}>
                  <button
                    class="page-link"
                    type="button"
                    onClick={handlePageChange(p)}
                  >
                    {p}
                  </button>
                </li>
              ))}
              <li class="page-item">
                <button
                  class="page-link"
                  type="button"
                  onClick={handlePageChange(page + 1)}
                  disabled={page === pages.length}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </main>
  );
};

export default RoomListing;
