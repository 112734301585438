import { useState, createContext, useEffect } from "react";
import Loader from "./Loader";

export const LoaderContext = createContext();

const LoaderProvider = ({ children }) => {
  const [loading, setIsLoading] = useState([]);
  const startLoading = (component) => {
    setIsLoading([...loading, component]);
  };

  const endLoading = (component) => {
    setIsLoading(loading.filter((c) => c !== component));
  };

  return (
    <>
      <Loader active={loading.length > 0} />
      <LoaderContext.Provider value={{ startLoading, endLoading }}>
        {children}
      </LoaderContext.Provider>
    </>
  );
};

const willLoad = (Component) => (props) => (
  <LoaderContext.Consumer>
    {(loadMethods) => <Component {...loadMethods} {...props} />}
  </LoaderContext.Consumer>
);

export const Loading = willLoad(({ startLoading, endLoading }) => {
  useEffect(() => {
    const name = Math.random().toString(36).slice(2);
    startLoading(name);
    return () => {
      endLoading(name);
    };
  }, []);
  return <></>;
});

export default LoaderProvider;
