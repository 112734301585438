import { Link } from "react-router-dom";
import "./error.scss";
const Error = ({ status = 404 }) => {
  return (
    <section className="error-page">
      <div className="container">
        <h1>
          {status} {"error_" + status}
        </h1>
        <p>{"error_description_" + status}</p>
        <Link className="btn btn-primary" to="/">
          {"link_go_to_main"}
        </Link>
      </div>
    </section>
  );
};

export default Error;
