import { useTranslation } from "react-i18next";
import { YMaps, Map as MapInstance, Placemark } from "react-yandex-maps";
import { Landmark, Phone, WorkingHours } from "../assets/icons";
import { useSetting } from "../utils/SettingsProvider";
import "./map.scss";

const coordinates = [41.297962, 69.249357];

const Map = () => {
  const { t } = useTranslation()
  const s = useSetting()
  const phones = s('phone').split(",")
  return (
    <div className="map-card container">
      <div className="row">
        <div className="col-md-8 col-12">
          <YMaps>
            <div id="ymap" style={{ height: 400, width: "100%" }}>
              <MapInstance
                defaultState={{ center: coordinates, zoom: 15 }}
                height={400}
                width="100%"
              >
                <Placemark geometry={coordinates} />
              </MapInstance>
            </div>
          </YMaps>
        </div>
        <div className="col-md-4 col-12">
          <dl className="map-card__info">
            <dt>
              <Landmark />
              {t("Address")}
            </dt>
            <dd>
              <address>{s("address")}</address>
            </dd>
            <dt>
              <Phone />
              {t("Phone number")}
            </dt>
            <dd>
              {phones.map((p, i) => <><a href={`tel:${p}`} key={i}>{p}</a><br /> </>)}
            </dd>
            <dt>
              <WorkingHours />
              {t("Working hours")}
            </dt>
            <dd>
              {s("working-hours")}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default Map;
