import Picture from "./Picture";
import "./_banner.scss";
import _ from "lodash"
import { Link } from "react-router-dom";

const getFontSize = (length) => {
  return _.clamp(10000 / length, 150, 250);
}

const Banner = ({ item }) => {
  const text = item.text || ""
  let Component = "div";
  let props = {}
  if (text.url) {
    if (text.url.startsWith("http")) {
      Component = "a"
      props = {
        href: text.url,
        target: "_blank",
        rel: "noopener noreferer"
      }
    }
    else {
      Component = Link
      props = {
        to: text.url
      }
    }
  }
  return (
    <Component {...props} className="banner" >
      <Picture image={item.image} className="banner__img" />
      <div className="banner__text" style={{ color: item.color, fontSize: `${getFontSize(text.length)}%` }}>{text}</div>
    </Component>
  );
}

export default Banner;
