import Map from "./Map";
import logo from "../assets/logo.png";
import Socials from "./Socials";
import { NavLink } from "react-router-dom";
import AdBanners from "../containers/AdBanners"
import {
  BuildingIcon,
  EntertainIcon,
  FoodIcon,
  HelpIcon,
  SalesIcon,
  ShopIcon,
  RoomIcon,
} from "../assets/icons";
import { withPages } from "../utils/DynamicPagesProvider";
import { ToggleFeedback } from "./Feedback";
import { useSetting } from "../utils/SettingsProvider";
import { useTranslation } from "react-i18next";
import { connectViewport } from "react-viewport-utils";

const takeIcon = (i) => {
  switch (i) {
    case 0:
      return <BuildingIcon />;
    case 1:
      return <RoomIcon />;
    default:
      return <HelpIcon />;
  }
};

const Footer = ({ pages, dimensions: { width } }) => {
  const s = useSetting()
  const { t } = useTranslation()

  const type = width >= 996 ? "footer" : "footer_mobile"
  return (
    <>
      <div className="container">
        <AdBanners type={type} />
      </div>
      <section className="map" style={{ paddingTop: "2rem" }}>
        <Map></Map>
      </section>
      <footer>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4 col-lg-3">
              <ul className="nav flex-column">
                <li className="nav-item">
                  <NavLink to="/news" className="nav-link">
                    <SalesIcon /> {s("trans_sales_and_promos")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/all/shop" className="nav-link">
                    <ShopIcon /> {s("trans_shop")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/all/entertainment" className="nav-link">
                    <EntertainIcon />
                    {s("trans_entertainment")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/all/food" className="nav-link">
                    <FoodIcon /> {s("trans_food")}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-lg-3">
              <ul className="nav flex-column">
                {pages.map((page, i) => (
                  <li className="nav-item" key={page.slug}>
                    <NavLink to={`/info/${page.slug}`} className="nav-link">
                      {takeIcon(i)}
                      {page.title}
                    </NavLink>
                  </li>
                ))}
                <li className="nav-item">
                  <ToggleFeedback icon={<HelpIcon />} />
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="row justify-content-center">
                <div className="col-8 col-md-12 pt-4 pt-lg-0">
                  <img
                    src={logo}
                    alt="Next TC"
                    className="mb-5"
                    style={{ display: "block", width: "100%", height: "auto" }}
                  />
                </div>
              </div>

              <Socials label={t("Follow us")} />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default withPages(connectViewport()(Footer));
