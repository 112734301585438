import { Facebook, Instagram, Telegram } from "../assets/icons";

const Socials = ({ label }) => (
  <ul className="icons">
    {!!label && <li>{label}</li>}
    <li>
      <a
        href={"https://telegram.me"}
        title="Telegram"
        target="_blank"
        rel="noreferrer"
      >
        <Telegram />
        <span className="sr-only">Telegram</span>
      </a>
    </li>
    <li>
      <a
        href="https://instagram.com/next"
        title="Instagram"
        target="_blank"
        rel="noreferrer"
      >
        <Instagram />
        <span className="sr-only">Intagram</span>
      </a>
    </li>
    <li>
      <a
        href="https://facebook.com"
        title="Facebook"
        target="_blank"
        rel="noreferrer"
      >
        <Facebook />
        <span className="sr-only">Facebook</span>
      </a>
    </li>
  </ul>
);

export default Socials;
