import { useHistory } from "react-router-dom";

const Path = ({ shape, active, onMouseOver = null, onBlur = null }) => {
  const history = useHistory();
  const handleClick = (e) => {
    e.preventDefault();
    if (shape.url) {
      history.push(shape.url);
    }
  };
  console.log(shape);
  return (
    <path
      d={shape.coords}
      className={`${active ? "shape-active" : ""} ${
        shape.url ? "has-url" : ""
      }`}
      onClick={handleClick}
    />
  );
};

const Polygon = ({
  shape,
  active,
  onClick = null,
  onMouseOver = null,
  onBlur = null,
}) => {
  const history = useHistory();
  const handleClick = onClick
    ? (e) => {
        e.preventDefault();
        onClick(shape);
      }
    : (e) => {
        e.preventDefault();
        if (shape.url) {
          history.push(shape.url);
        }
      };

  const className = `${active ? "shape-active" : ""} ${
    shape.url ? "has-url" : ""
  }`;

  return (
    <polygon
      points={shape.coords}
      className={className}
      onClick={handleClick}
      onMouseOver={(e) => {
        onMouseOver(shape.targetable_id);
      }}
      onBlur={(e) => {
        onBlur();
      }}
    />
  );
};

const Shape = ({ item: shape, active = false, ...rest }) =>
  shape.type == "path" ? (
    <Path shape={shape} active={active} {...rest} />
  ) : (
    <Polygon shape={shape} active={active} {...rest} />
  );
export default Shape;
