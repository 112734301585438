import gql from "graphql-tag";

const imageFragment = gql`
  fragment ImageSubquery on image {
    url_webp
    url
    preview_url
    preview_url_webp
    height
    width
    preview_height
    preview_width
    thumb_url
  }
`;

export const POSTS_LISTING = gql`
  query Posts($page: Int!) {
    posts(page: $page) {
      data {
        id
        published_at
        title
        content
        excerpt
        image {
          ...ImageSubquery
        }
      }
      total
      per_page
    }
  }
  ${imageFragment}
`;

export const POSTS_ROW = gql`
  query Posts($page: Int!, $type: String!, $limit: Int!, $not: Int) {
    posts(page: $page, type: $type, limit: $limit, not: $not) {
      data {
        id
        published_at
        title
        excerpt
        slug
        type
        image {
          preview_url
          preview_url_webp
          thumb_url
          preview_width
          preview_height
        }
      }
      total
      per_page
    }
  }
`;

export const GET_POST = gql`
  query Post($slug: String!, $type: String!) {
    post(slug: $slug, type: $type) {
      id
      published_at
      title
      excerpt
      content
      type
      slug
      image {
        preview_url
        preview_url_webp
        thumb_url
        preview_width
        preview_height
        width
        height
        url
        url_webp
      }
    }
  }
`;
export const GET_FLOORS = gql`
  query Floors {
    floors {
      number
      id
      type
      rooms {
        id
        name
        type
        logo {
          preview_url
          preview_url_webp
          thumb_url
          preview_width
          preview_height
        }
      }
      map {
        preview_url
        preview_url_webp
        thumb_url
        preview_width
        preview_height
        width
        height
        url
        url_webp
        shapes {
          id
          type
          coords
          targetable_id
          targetable_type
          url
        }
      }
    }
  }
`;

export const ROOMS_ROW = gql`
  query Rooms(
    $page: Int!
    $like: Int
    $type: String
    $tag: Int
    $limit: Int
    $search: String
  ) {
    rooms(
      page: $page
      like: $like
      type: $type
      tag: $tag
      limit: $limit
      search: $search
    ) {
      data {
        id
        name
        banners {
          preview_url
          preview_url_webp
          thumb_url
          preview_width
          preview_height
        }
      }
      total
      per_page
    }
  }
`;

export const GET_BANNERS = gql`
  query Banners($type: String) {
    banners(type: $type) {
      id
      order
      text
      color
      image {
        ...ImageSubquery
      }
    }
  }
  ${imageFragment}
`;

export const GET_ROOM = gql`
  query Room($id: Int!) {
    room(id: $id) {
      id
      name
      number
      description
      excerpt
      facebook
      instagram
      telegram
      phone_list
      floor {
        number
      }
      type
      logo {
        preview_url
        preview_url_webp
        thumb_url
        preview_width
        preview_height
      }
      banners {
        ...ImageSubquery
      }
      gallery {
        ...ImageSubquery
      }
    }
  }
  ${imageFragment}
`;

export const TAGS = gql`
  query Tags($type: String!) {
    tags(type: $type) {
      id
      name
    }
  }
`;

export const PAGES = gql`
  query Pages {
    posts(type: "PAGE") {
      data {
        title
        slug
      }
    }
  }
`;

export const GET_SETTINGS = gql`
  query Settings($locale: String!) {
    settings(locale: $locale) {
      key
      value
    }
  }
`;
