import { useQuery } from "@apollo/client";
import { createContext } from "react";
import { PAGES } from "../queries";
import { Loading } from "./LoaderProvider";

export const DynamicPagesContext = createContext();

const DynamicPagesProvider = ({ children }) => {
  const { loading, error, data } = useQuery(PAGES);
  if (error) return `Error! ${error.message}`;

  return (
    <>
      {loading && <Loading />}
      <DynamicPagesContext.Provider
        value={{ pages: data ? data.posts.data : [] }}
      >
        {children}
      </DynamicPagesContext.Provider>
    </>
  );
};

export const withPages = (Component) => (props) => (
  <DynamicPagesContext.Consumer>
    {(pages) => <Component {...pages} {...props} />}
  </DynamicPagesContext.Consumer>
);

export default DynamicPagesProvider;
