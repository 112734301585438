import React, { useState } from "react";
import PostCard from "../components/PostCard";
import { range } from "lodash/fp";
import { useQuery } from "@apollo/client";
import { POSTS_ROW } from "../queries";
import { connectViewport } from "react-viewport-utils";
import { Loading } from "../utils/LoaderProvider";
import { Link } from "react-router-dom";
import { BreadcrumbDivider } from "../assets/icons";
import { useTranslation } from "react-i18next";
import { useSetting } from "../utils/SettingsProvider";
import PostGrid from "./PostGrid";

const PER_PAGE = 12;

const PostListing = ({
  limit = PER_PAGE,
  type = "promo",
}) => {
  const s = useSetting();
  const { t } = useTranslation()
  const [page, setPage] = useState(1);
  const { loading, error, data } = useQuery(POSTS_ROW, {
    variables: { type, page: page, limit },
  });
  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const posts = data.posts.data;
  const pages = range(1)(Math.ceil(data.posts.total / PER_PAGE) + 1);

  const handlePageChange = (page) => (e) => {
    e.preventDefault();
    setPage(page);
  };
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">{t("Main Page")}</Link>
                  <BreadcrumbDivider />
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {s("trans_sales_and_promos")}
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-lg-6">
            {page.length > 1 && (
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-end">
                  <li class="page-item">
                    <button
                      class="page-link"
                      type="button"
                      onClick={handlePageChange(page - 1)}
                      disabled={page === 1}
                    >
                      {t("Previous")}
                    </button>
                  </li>
                  {pages.map((p) => (
                    <li
                      class={`page-item${p === page ? " active" : ""}`}
                      key={p}
                    >
                      <button
                        class="page-link"
                        type="button"
                        onClick={handlePageChange(p)}
                      >
                        {p}
                      </button>
                    </li>
                  ))}
                  <li class="page-item">
                    <button
                      class="page-link"
                      type="button"
                      onClick={handlePageChange(page + 1)}
                      disabled={page === pages.length}
                    >
                      {t("Next")}
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
        {!!posts.length && (<PostGrid posts={posts}></PostGrid>)}
      </div>
    </section>
  );
};



export default (PostListing);
