import { Link } from "react-router-dom";
import "./post-card.scss";

const PostCard = ({ post, className = "" }) => (
  <Link className={`post-card ${className}`} to={`/${post.type}/${post.slug}`}>
    <div className="post-card__inner">
      <div className="post-card__img">
        <img src={post.image.preview_url_webp} alt={post.title} />
      </div>
      <div className="post-card__title" >
        {post.title}
      </div>
      <div className="post-card__excerpt">{post.excerpt}</div>
    </div>
  </Link>
);

export default PostCard;
