import { createContext, useEffect, useState } from "react";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import Modal from "../utils/Modal";
import { useMutation } from "@apollo/client";

const FeedbackContext = createContext();

export const FeedbackProvider = ({ children }) => {
  const [open, toggle] = useState(false);
  const onClose = () => {
    toggle(false);
  };
  const onOpen = () => {
    toggle(true);
  };
  return (
    <FeedbackContext.Provider value={{ open, onClose, onOpen }}>
      {children}
      <Feedback open={open} onClose={onClose} />
    </FeedbackContext.Provider>
  );
};

export const viewFeedback = (Component) => (props) => (
  <FeedbackContext.Consumer>
    {({ open, onClose, onOpen }) => (
      <Component onOpen={onOpen} open={open} onClose={onClose} {...props} />
    )}
  </FeedbackContext.Consumer>
);

const CREATE_MESSAGE = gql`
  mutation createMessage(
    $name: String!
    $receiver: String!
    $phone: String!
    $comment: String
  ) {
    createMessage(
      name: $name
      phone: $phone
      receiver: $receiver
      comment: $comment
    ) {
      phone
      name
      receiver
    }
  }
`;

const Feedback = ({ onClose, open }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [createMessage, { data: success }] = useMutation(CREATE_MESSAGE);

  const handleSubmit = (e) => {
    e.preventDefault();
    createMessage({ variables: data }).then(() => {
      setData({});
    });
  };
  const handleInputChange = (e) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const canBeSent =
    data.name && data.phone && data.receiver && data.receiver !== "-1";

  return (
    <Modal open={open} onClose={onClose}>
      <form>
        <h2>{t("Feedback")}</h2>
        {!!success && !!success.createMessage && (
          <div className="alert alert-success">
            <h4 className="text-center mb-0">
              {t("Thank you for your feedback")}
            </h4>
          </div>
        )}
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="Name">{t("Name")}</label>
              <input
                type="text"
                name="name"
                onChange={handleInputChange}
                value={data.name || ""}
                required
                id="Name"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="Phone">{t("Phone")}</label>
              <input
                onChange={handleInputChange}
                value={data.phone || ""}
                type="tel"
                name="phone"
                required
                id="Phone"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="receiver">{t("Receiver department")}</label>
              <select
                name="receiver"
                value={data.receiver || "-1"}
                required
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="-1">{t("Select department")}</option>
                <option value="Administration">{t("Administration")}</option>
                <option value="Marketing department">
                  {t("Marketing department")}
                </option>
                <option value="Rental department">
                  {t("Rental department")}
                </option>
                <option value="Complaints and suggestions">
                  {t("Complaints and suggestions")}
                </option>
              </select>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="comment">{t("Comment")}</label>
              <textarea
                name="comment"
                id="comment"
                value={data.comment || ""}
                className="form-control"
                onChange={handleInputChange}
                rows="10"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            type="submit"
            onClick={handleSubmit}
            className="btn-secondary btn"
            disabled={!canBeSent}
          >
            {t("Contact me")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export const ToggleFeedback = viewFeedback(({ onOpen, icon = null }) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className="nav-link"
      onClick={(e) => {
        e.preventDefault();
        onOpen();
      }}
    >
      {!!icon && icon}
      {t("Open feedback")}
    </button>
  );
});

export default Feedback;
