import React from "react";
import { useQuery } from "@apollo/client";
import { TAGS } from "../queries";
import TagRooms from "./TagRooms";
import { Helmet } from "react-helmet";
import { Loading } from "../utils/LoaderProvider";

const RoomsByType = ({ type }) => {
  const { loading, error, data } = useQuery(TAGS, {
    variables: { type },
  });
  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const tags = data.tags;
  return (
    <main style={{ padding: "12px 0 30px" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Next shopping center: All shops </title>
      </Helmet>
      {tags.map((tag) => (
        <TagRooms tag={tag} type={type} key={tag.id} />
      ))}
    </main>
  );
};

export default RoomsByType;
