import React from "react";
import RoomCard from "../components/RoomCard";
import { useQuery } from "@apollo/client";
import { ROOMS_ROW } from "../queries";
import { connectViewport } from "react-viewport-utils";
import RoomCardShimmer from "../shimmers/RoomCardShimmer";
import { Link } from "react-router-dom";

const RoomRow = ({ type, like, title }) => {
  const { loading, error, data } = useQuery(ROOMS_ROW, {
    variables: { page: 1, like, limit: 6, type },
  });
  if (loading)
    return (
      <section>
        <div className="container">
          <h2>{title}</h2>
          <div className="row">
            {[0, 1, 2].map((r) => (
              <div className="col-md-4" key={r}>
                <RoomCardShimmer />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  if (error) return `Error! ${error.message}`;

  const rooms = data.rooms.data;
  return rooms.length === 0 ? (
    <></>
  ) : (
      <section className="pt-3 pt-5">
        <div className="container">
          <h2><Link to={`/all/${type}`}>{title}</Link></h2>
          <div className="row">
            {rooms.map((r) => (
              <div className="col-md-4 col-6" key={r.id}>
                <RoomCard item={r} />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
};

export default connectViewport()(RoomRow);
