import Banners from "../components/Banners";
import MainView from "../containers/MainView";
import PostRow from "../containers/PostRow";
import RoomRow from "../containers/RoomRow";
import { useSetting } from "../utils/SettingsProvider";

const Main = () => {
  const s = useSetting();
  return (
    <>
      <Banners />
      <PostRow type="promo" title={s("trans_sales_and_promos")} />
      <MainView />
      <RoomRow type="shop" title={s("trans_shop")} />
      <RoomRow type="entertainment" title={s("trans_entertainment")} />
      <RoomRow type="food" title={s("trans_food")} />
    </>
  );
};

export default Main;
