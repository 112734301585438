import mainview from "../assets/mainview.jpg";
import mainviewWebp from "../assets/mainview.webp";
import Selection from "../selection";
import "./_mainview.scss";
const img = {
  url_webp: mainviewWebp,
  url: mainview,
  preview_url: mainview,
  preview_url_webp: mainview,
  width: 1920,
  height: 1080,
};

const shapes = [
  {
    id: 1,
    type: "polygon",
    coords: `1320.89,560.74 1320.89,566.07 1317.33,569.33 1297.78,694.96 1168.89,835.11 405.05,462.78 
    394.38,411.46 381.93,351.56 378.07,349.48 376.89,345.33 485.35,300.03 526.37,282.9 581.63,304.15 582.22,307.41 580.15,309.19 
    580.15,317.48 719.93,370.66 764.74,387.7 799.41,370.52 807.11,370.52 814.52,372 814.52,374.96 817.19,375.85 828.15,370.52 
    897.19,395.7 897.19,400.74 903.7,404.59 905.48,408.44 905.48,440.74 1196.44,548.89 1224.3,525.19 `,
    url: "/block/mall",
  },
  {
    id: 2,
    type: "polygon",
    coords: `381.93,351.56 394.38,411.46 330.96,382.07 317.33,389.48 240.89,353.63 221.63,282.22 219.85,281.33 
    218.37,275.7 299.56,247.85 362.37,273.33 368.3,271.56 390.81,279.26 412.15,271.56 485.35,300.03 376.89,345.33 378.07,349.48 `,
    url: "/block/administration",
  },
  {
    id: 3,
    type: "polygon",
    coords: `610.03,296.33 610.02,296.33 580.15,309.19 580.15,317.48 719.93,370.66 870.81,294.37 870.81,239.26 
    831.98,227.73 794.96,216.74 729.48,244.92 729.48,237.93 723.26,236 700.96,246.22 700.96,257.2 649.93,279.16 649.93,270.52 
    642.96,268.89 617.78,280.15 617.78,292.99`,
    url: "/block/kids",
  },
  {
    id: 4,
    type: "polygon",
    coords: `1258.37,54.37 1258.37,62.96 1249.48,66.22 1249.48,76.3 1162.37,116.89 1175.7,121.63 1175.41,124.59 
    1172.74,124.89 1172.74,141.19 1098.37,176.15 1087.7,173.78 1071.41,180.89 1084.44,184.74 1084.37,191.04 1083.04,191.63 
    1082.89,202.44 1006.67,241.93 878.96,205.48 831.98,227.73 794.96,216.74 729.48,244.92 729.48,237.93 723.26,236 700.96,246.22 
    700.96,257.2 649.93,279.16 649.93,270.52 642.96,268.89 617.78,280.15 617.78,292.99 610.03,296.33 610.02,296.33 490.67,252.89 
    488.3,236.3 479.7,233.93 478.52,223.56 485.93,220 501.04,225.63 1057.19,20.3 1057.19,18.22 1060.74,16.74 1099.26,22.96 
    1101.63,25.93 1105.78,24.74 1108.15,26.22 1112.3,26.22 1117.04,28.3 1123.85,27.11 1131.26,26.81 1141.04,28.89 1149.63,29.48 
    1196.74,42.52`,
    url: "/block/icering",
  },
];

const MainView = () => {
  return (
    <div className="main-view">
      <Selection shapes={shapes} image={img} />
    </div>
  );
};

export default MainView;
