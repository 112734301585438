import { useEffect, useState } from "react";
import Picture from "../components/Picture";
import SVG from "./SVG";
import "./_selection.scss";

const Selection = ({
  shapes,
  image,
  onBlur = null,
  onMouseOver = null,
  selected = null,
}) => {
  const [current, setCurrent] = useState(0);
  useEffect(() => {
    if (shapes.length) {
      setCurrent(shapes[0].id);
    }
  }, [shapes]);
  console.log({ selected });

  return (
    <div className="selection">
      <Picture image={image} className="selection__image"></Picture>
      {!!shapes.length && (
        <SVG
          shapes={shapes}
          height={image.height}
          width={image.width}
          onBlur={onBlur}
          selected={selected}
          onMouseOver={onMouseOver}
        />
      )}
    </div>
  );
};

export default Selection;
