import React from "react";
import PostCard from "../components/PostCard";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useQuery } from "@apollo/client";
import { POSTS_ROW } from "../queries";
import { connectViewport } from "react-viewport-utils";
import { Link } from "react-router-dom";

const getSlidesCount = (width) => {
  if (width <= 768) {
    return 1;
  }
  if (width <= 996) {
    return 2;
  }
  return 3;
};

const PostRow = ({
  title,
  type,
  limit = 3,
  not = null,
  dimensions: { width: windowWidth },
}) => {
  const { loading, error, data } = useQuery(POSTS_ROW, {
    variables: { type, page: 1, limit, not },
  });
  const countSlides = getSlidesCount(windowWidth);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const posts = data.posts.data;

  return (
    <section className="py-4">
      <div className="container">
        <h2><Link to="/promo">{title}</Link></h2>
        {!!posts.length && (
          <div className="row">
            {posts.map((p, index) => (
              <PostCard post={p} className="col-md-4" key={index} />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};


export default connectViewport()(PostRow);
