import { animated, useTransition } from "react-spring";
import "./loader.scss";
import { Loader as Spinner } from "react-loaders";
import logo from "../assets/logo.png";
// also available as `default`

const Loader = ({ active }) => {
  const transitions = useTransition(active, null, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  return transitions.map(
    ({ item, props, key }) =>
      item && (
        <animated.div key={key} style={props} className="preloader">
          <div className="preloader__inner">
            <div style={{ textAlign: "center" }}>
              <div className="preloader__row">
                <Spinner type="square-spin" active />
                <img src={logo} alt="Next Logo" />
              </div>
            </div>
          </div>
        </animated.div>
      )
  );
};

export default Loader;
