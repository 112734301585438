import React from "react";
import Post from "../components/PostPage";
import { useQuery } from "@apollo/client";
import { GET_POST } from "../queries";
import PostRow from "./PostRow";
import { Loading } from "../utils/LoaderProvider";
import { Link } from "react-router-dom";
import { BreadcrumbDivider } from "../assets/icons";
import { useTranslation } from "react-i18next";
import { useSetting } from "../utils/SettingsProvider";

const PostContainer = ({ type, slug }) => {
  const { t } = useTranslation()
  const s = useSetting()
  const { loading, error, data } = useQuery(GET_POST, {
    variables: { slug, type },
  });
  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const post = data.post;
  return (
    <>
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to="/">{t("Main Page")}</Link>
              <BreadcrumbDivider />
            </li>
            <li class="breadcrumb-item">
              <Link to={`/${type}`}>{s("trans_sales_and_promos")}</Link>
              <BreadcrumbDivider />
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {post.title}
            </li>
          </ol>
        </nav>
        <Post post={post} />
      </div>
      <PostRow type={type} title={s("trans_sales_and_promos")} limit={3} not={post.id} />
    </>
  );
};

export default PostContainer;
