import { Children, cloneElement } from 'react'
import { useKeenSlider } from "keen-slider/react";
import "./_carousel.scss"

export default ({ children, perview }) => {
    const [sliderRef] = useKeenSlider({ slidesPerView: perview });
    return <div ref={sliderRef} className="keen-slider carousel">
        {!!children && Children.map(children, child => (
            <div className="keen-slider__slide">{cloneElement(child)}</div>
        ))}
    </div>
}
