import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./components/Footer";
import Topnav from "./components/Topnav";
import Post from "./pages/Post";
import Main from "./pages/Main";
import Room from "./pages/Room";
import { ViewportProvider } from "react-viewport-utils";
import Rooms from "./pages/Rooms";
import ScrollTop from "./components/ScrollTop";
import PostListing from "./containers/PostListing";
import LoaderProvider from "./utils/LoaderProvider";
import DynamicPagesProvider from "./utils/DynamicPagesProvider";
import Error from "./utils/Error";
import { FeedbackProvider } from "./components/Feedback";
import Page from "./pages/Page";
import PageSearch from "./pages/PageSearch";
import SettingsProvider from "./utils/SettingsProvider";
import Plan from "./components/Plan";

function App() {
  return (
    <Router>
      <ViewportProvider>
        <LoaderProvider>
          <SettingsProvider>
            <FeedbackProvider>
              <DynamicPagesProvider>
                <Topnav />
                <div className="App" style={{ minHeight: 200 }}>
                  <ScrollTop>
                    <Route
                      path="/block/:type(mall|icering|administration|kids)"
                      component={Plan}
                      exact
                    />
                    <Switch>
                      <Route path="/rooms/:room_id" exact component={Room} />
                      <Route
                        path="/all/:type(shop|food|entertainment)"
                        component={Rooms}
                      />
                      <Route path="/promo" exact component={PostListing} />
                      <Route path="/info/:slug" exact component={Page} />
                      <Route
                        path="/:type(news|promo|page)/:slug"
                        exact
                        component={Post}
                      />
                      <Route path="/search" component={PageSearch} />
                      <Route path="/" component={Main} />
                      <Error />
                    </Switch>
                  </ScrollTop>
                </div>
                <Footer />
              </DynamicPagesProvider>
            </FeedbackProvider>
          </SettingsProvider>
        </LoaderProvider>
      </ViewportProvider>
    </Router>
  );
}

export default App;
