import { Link, NavLink } from "react-router-dom";
import logo from "../assets/logo.png";
import { FoodIcon, ShopIcon, EntertainIcon, SalesIcon } from "../assets/icons";
import { withPages } from "../utils/DynamicPagesProvider";
import { ToggleFeedback } from "./Feedback";
import Socials from "./Socials";
import Search from "./Search";
import { connectViewport } from "react-viewport-utils";
import MobileMenu from "./MobileMenu";
import { useSetting } from "../utils/SettingsProvider";
import { useState } from "react";
import LocaleSwitch, { LocaleToggleButton } from "./LocaleSwitch";

const DesktopTopnav = ({ pages, openLocale }) => {
  const s = useSetting();
  return (
    <nav className="topnav">
      <div className="topnav__top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2 col-12">
              <Link to="/">
                <img src={logo} alt="Next TC" className="logo" />
              </Link>
            </div>
            <div className="col-lg-7 col-12">
              <ul className="nav justify-content-center">
                {pages.map((p) => (
                  <li className="nav-item" key={p.slug}>
                    <Link to={`/info/${p.slug}`} className="nav-link">
                      {p.title}
                    </Link>
                  </li>
                ))}
                <li className="nav-item">
                  <ToggleFeedback />
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-12">
              <Socials />
            </div>
            <div className="col-lg-1 col-12">
              <ul className="icons">
                <li>
                  <Search />
                </li>
                <li>
                  <LocaleToggleButton
                    onClick={(e) => {
                      e.preventDefault();
                      openLocale();
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="topnav__bottom">
        <div className="container">
          <ul className="nav justify-content-around">
            <li className="nav-item">
              <NavLink to="/promo" className="nav-link">
                <SalesIcon /> {s("trans_sales_and_promos")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/all/shop" className="nav-link">
                <ShopIcon /> {s("trans_shop")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/all/entertainment" className="nav-link">
                <EntertainIcon />
                {s("trans_entertainment")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/all/food" className="nav-link">
                <FoodIcon /> {s("trans_food")}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const Topnav = ({ pages, dimensions: { width: windowWidth } }) => {
  const [localeOpen, toggleLocale] = useState(false);
  return (
    <>
      {windowWidth >= 996 ? (
        <DesktopTopnav pages={pages} openLocale={() => toggleLocale(true)} />
      ) : (
        <MobileMenu pages={pages} openLocale={() => toggleLocale(true)} />
      )}
      <LocaleSwitch
        open={localeOpen}
        close={(e) => {
          toggleLocale(false);
        }}
      />
    </>
  );
};

export default withPages(connectViewport()(Topnav));
