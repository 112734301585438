import React from "react";
import Post from "../components/PostPage";
import { useQuery } from "@apollo/client";
import { GET_POST } from "../queries";
import { Loading } from "../utils/LoaderProvider";
import Error from "../utils/Error";
import { Link } from "react-router-dom";
import { BreadcrumbDivider } from "../assets/icons";

const PageContainer = ({ slug }) => {
  const { loading, error, data } = useQuery(GET_POST, {
    variables: { slug, type: "page" },
  });
  if (loading) return <Loading />;
  if (error) return <Error />;

  const post = data.post;
  if (!post) return <Error />;
  return (
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <Link to="/">{"Main Page"}</Link>
            <BreadcrumbDivider />
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {post.title}
          </li>
        </ol>
      </nav>
      <Post post={post}></Post>
    </div>
  );
};

export default PageContainer;
