import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AngleDown } from "../assets/icons";
import RoomCard from "../components/RoomCard";
import { ROOMS_ROW } from "../queries";
import RoomCardShimmer from "../shimmers/RoomCardShimmer";
import "./tag-rooms.scss";

const TagRooms = ({ tag, type }) => {
  const [all, toggle] = useState(false);
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(ROOMS_ROW, {
    variables: { page: 1, tag: tag.id, limit: -1, type },
  });
  if (loading)
    return (
      <section>
        <div className="container">
          <h2>{tag.name}</h2>
          <div className="row">
            {[0, 1, 2].map((r) => (
              <div className="col-md-4" key={r.id}>
                <RoomCardShimmer />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  if (error) return `Error! ${error.message}`;
  const rooms = data.rooms.data;
  const roomsToShow = all ? rooms : rooms.slice(0, 6);
  return (
    <div className="container tag-rooms">
      <h2>{tag.name}</h2>
      <div className="row">
        {roomsToShow.map((r) => (
          <div className="col-md-4 col-6" key={r.id}>
            <RoomCard item={r} />
          </div>
        ))}
      </div>
      {rooms.length > 6 && (
        <div className="text-right">
          <button
            type="button"
            className="tag-rooms__button"
            onClick={(e) => {
              e.preventDefault();
              toggle(!all);
            }}
          >
            {all ? t("Show less") : t("Show more")}
            <AngleDown style={{ transform: `rotate(${all * 180}deg)` }} />
          </button>
        </div>
      )}
    </div>
  );
};

export default TagRooms;
