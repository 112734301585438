import "./post-page.scss";

const Post = ({ post }) => (
  <article className="post">
    <div className="row justify-content-center">
      <div className="col-md-6">
        <img
          src={post.image.url_webp}
          alt={post.title}
          className="post__image"
        />
      </div>
      <div className="col-md-6 post__info">
        <h1 className="post__title">{post.title}</h1>
        <p className="post__excerpt">{post.excerpt}</p>
      </div>
      <div className="col-lg-10 post__content">
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </div>
    </div>
  </article>
);

export default Post;
