import { T } from "lodash/fp"
import { useTranslation } from "react-i18next"
import { Locale } from "../assets/icons"
import Modal from "../utils/Modal"
import "./_locale-switch.scss"

const locales = [
    'ru','en','uz'
]

const localeInfo = {
    ru:"Русский",
    en:"English",
    uz:"O`zbek"
}

const LocaleSwitch = ({open,close})=>{
    const {i18n,t} = useTranslation() 

    const handleLocaleChange = (e)=>{
        e.preventDefault()
        i18n.changeLanguage(e.target.value,close)
    }
    return <Modal className="locale-switch" open={open} onClose={close}>
        <h4>{t("Choose Language")}</h4>
        {locales.map(l=>
            <button className={`locale-button`} disabled={i18n.language === l} value={l}  onClick={handleLocaleChange}>
            {localeInfo[l]}</button> 
            )} 
    </Modal>
}

export const LocaleToggleButton = ({onClick})=>(
    <button className="locale-switch__toggler" onClick={onClick}>
        <Locale />  
    </button>
)

export default LocaleSwitch