import { Link, NavLink, withRouter } from "react-router-dom";
import logo from "../assets/logo.png";
import Socials from "./Socials";
import Search from "./Search";
import { FoodIcon, ShopIcon, EntertainIcon, SalesIcon } from "../assets/icons";
import { withPages } from "../utils/DynamicPagesProvider";
import { ToggleFeedback } from "./Feedback";
import { useEffect, useState } from "react";
import { useSetting } from "../utils/SettingsProvider";

const MobileMenu = ({ pages, history }) => {
  const [open, toggle] = useState(false);
  useEffect(() => {
    const unlisten = history.listen(() => {
      toggle(false);
    });
    return () => {
      unlisten();
    };
  }, []);
  const s = useSetting()
  return (
    <nav
      className={`topnav topnav-mobile ${open ? "topnav-mobile--open" : ""}`}
    >
      <div className="topnav-mobile__fixed">
        <div className="row">
          <div className="col-6">
            <Link to="/" className="logo">
              <img src={logo} alt="Next TC" className="logo" />
            </Link>
          </div>
          <div className="col-6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Search style={{ marginRight: 32 }} />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  toggle(!open);
                }}
                className="bar"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ul className="nav flex-column">
        <li className="nav-item">
          <NavLink to="/promo" className="nav-link">
            <SalesIcon /> {s("trans_sales_and_promos")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/all/shop" className="nav-link">
            <ShopIcon /> {s("trans_shop")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/all/entertainment" className="nav-link">
            <EntertainIcon />
            {s("trans_entertainment")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/all/food" className="nav-link">
            <FoodIcon /> {s("trans_food")}
          </NavLink>
        </li>
        {pages.map((p) => (
          <li className="nav-item" key={p.slug}>
            <Link to={`/info/${p.slug}`} className="nav-link">
              {p.title}
            </Link>
          </li>
        ))}
        <li className="nav-item">
          <ToggleFeedback />
        </li>
        <li className="nav-item">
          <Socials />
        </li>
        <li className="nav-item">
          <ul className="icons">
            <li></li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};
export default withPages(withRouter(MobileMenu));
