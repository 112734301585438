import { useTranslation } from "react-i18next";

const SearchField = ({ searchKey, setKey }) => {
  const { t } = useTranslation()
  return (
    <div className="container pt-3">
      <h3>{t("Search")}</h3>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          value={searchKey}
          onChange={(e) => {
            e.preventDefault();
            setKey(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default SearchField;
