import React, { useState } from "react";
import Room from "../components/RoomPage";
import { useQuery } from "@apollo/client";
import { GET_ROOM } from "../queries";
import RoomRow from "./RoomRow";
import { Loading } from "../utils/LoaderProvider";
import Error from "../utils/Error";
import { Link } from "react-router-dom";
import { BreadcrumbDivider } from "../assets/icons";
import { useTranslation } from "react-i18next";
import { useSetting } from "../utils/SettingsProvider";

const RoomContainer = ({ id }) => {
  const { t } = useTranslation();
  const s = useSetting()
  const { loading, error, data } = useQuery(GET_ROOM, {
    variables: { id: id * 1 },
  });
  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const room = data.room;
  if (!room) {
    return <Error />;
  }
  return (
    <>
      <div className="container room-breadcrumb">
        <ol class="breadcrumb white">
          <li class="breadcrumb-item">
            <Link to="/">{t("Main Page")}</Link>
            <BreadcrumbDivider />
          </li>
          <li class="breadcrumb-item">
            <Link to={`/all/${room.type.toLowerCase()}`}>
              {s(`trans_${room.type.toLowerCase()}`)}
            </Link>
            <BreadcrumbDivider />
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {room.name}
          </li>
        </ol>
      </div>
      <Room room={room}></Room>
      <RoomRow like={room.id} />
    </>
  );
};

export default RoomContainer;
