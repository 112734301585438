import { Link } from "react-router-dom";
import Picture from "./Picture";
import "./room-card.scss";

const RoomCard = ({ item }) => {
  const banner = item.banners[0];
  return (
    <Link className="room-card" to={`/rooms/${item.id}`}>
      {!!banner && <Picture image={banner} className="room-card__img" />}
      <div className="room-card__name">{item.name}</div>
    </Link>
  );
};

export default RoomCard;
