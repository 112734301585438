import { sortBy } from "lodash";

const FloorNav = ({ items, onSelect = null, current }) => (
  <aside>
    <nav>
      {sortBy(items, "number", "desc").map((item) => (
        <button
          key={item.id}
          onClick={(e) => {
            e.preventDefault();
            onSelect && onSelect(item);
          }}
          className={current == item.id ? "active" : ""}
        >
          {item.number}
        </button>
      ))}
    </nav>
  </aside>
);

export default FloorNav;
