import { useQuery } from "@apollo/client";
import { createContext, useContext } from "react";
import _ from "lodash/fp";
import { GET_SETTINGS } from "../queries";
import { Loading } from "./LoaderProvider";
import { useTranslation } from "react-i18next";

export const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const { loading, error, data } = useQuery(GET_SETTINGS, {
    variables: { locale: i18n.language },
  });
  if (error) return `Error! ${error.message}`;
  return (
    <>
      {loading && <Loading />}
      <SettingsContext.Provider
        value={data && data.settings ? _.keyBy("key")(data.settings) : {}}
      >
        {children}
      </SettingsContext.Provider>
    </>
  );
};

export const withSettings = (Component) => (props) => (
  <SettingsProvider.Consumer>
    {(settings) => <Component settings={settings} {...props} />}
  </SettingsProvider.Consumer>
);

export const useSetting = () => {
  const settings = useContext(SettingsContext);
  return (key) => {
    return settings[key] ? settings[key].value : key;
  };
};

export default SettingsProvider;
