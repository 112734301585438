const Picture = ({ image, alt = "", className = "" }) => (
  <picture>
    {!!image.url_webp && (
      <source
        media="(min-width:992px)"
        srcSet={image.url_webp}
        type="image/jpeg"
      />
    )}
    {!!image.url && (
      <source media="(min-width:992px)" srcSet={image.url} type="image/jpeg" />
    )}
    <source srcSet={image.preview_url_webp} type="image/webp" />
    <source srcSet={image.preview_url} type="image/jpeg" />
    <img
      src={image.thumb_url}
      alt={alt}
      className={className}
      height={image.height || image.preview_height}
      width={image.width || image.preview_width}
    />
  </picture>
);

export default Picture;
