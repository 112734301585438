import "./roomCard.scss";

const RoomCardShimmer = () => {
  return (
    <div className="room-card">
      <div className="animatedBG" style={{ height: 250, width: "100%" }}></div>
    </div>
  );
};

export default RoomCardShimmer;
