import { useQuery } from "@apollo/client";
import Banner from "../components/Banner";
import Carousel from "../components/Carousel";
import { GET_BANNERS } from "../queries";
import "./ad-banners.scss"

const AdBanners = ({ type = "side" }) => {
    const { loading, error, data } = useQuery(GET_BANNERS, {
        variables: { type: type }
    });
    if (loading) return <div className="meta-banners"></div>;
    if (error) return `Error! ${error.message}`;
    const { banners } = data;
    return (
        <div className="meta-banners">{!!banners.length
            && (
                <Carousel perview={1}>
                    {
                        banners.map((b, i) => (
                            <Banner item={b} key={i}></Banner>
                        ))
                    }
                </Carousel>
            )}

        </div>
    );
};

export default AdBanners