import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SearchIcon } from "../assets/icons";

const Search = (props) => {
  const { t } = useTranslation()
  return (
    <Link to="/search" className="search-link" {...props}>
      <SearchIcon />
      <span className="sr-only">{t("Search")}</span>
    </Link>
  )
};

export default Search;
